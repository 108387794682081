import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Button, Col, Dropdown, Modal, Row } from "react-bootstrap";
import { API_URL } from "../constant";
import { handleDeleteCollection } from "../shared/api";
import { toast } from "react-toastify";
import { rightSelector } from "../redux/rightSlice";
import { useSelector } from "react-redux";
import axios from "axios";
import Logo from '../assets/images/small-logo.png'

function NFTCollectionPost({ colData, dataAfterDelete, dataAfterApprove }) {
  const [isDelete, setisDelete] = useState(false);
  const { rightAccess } = useSelector(rightSelector);

  const [email, setemail] = useState('');
  const deleteCollection = (id) => {
    setisDelete(false)
    handleDeleteCollection(id)
      .then((res) => {
        dataAfterDelete(id);
        toast.info("Collection Deleted Successfully ");
      })
      .catch((err) => { });
  };
  const handleClose = () => setisDelete(false);
  const approveCollection = (id, approve) => {
    axios
      .post(API_URL + `api/Nft/ApproveCollection?collectionId=${id}&IsApproved=${approve}`, {}, {
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${localStorage.getItem("Token")}`,
        },
      })
      .then((res) => {


        if (res.data) {
          dataAfterApprove(id, approve)
          approve ? toast.success("Collection Approved") : toast.warning("Collection Unapproved")

        }
      })
      .catch((err) => {
        if (err.response.data.message === 'Only super admin approve collection not found') {
          toast.info("Only super admin can approve collection")

        }
      });
  }
  useEffect(() => {
    setemail(localStorage.getItem('email'))
  }, []);

  return (
    <>
      <Modal centered show={isDelete} onHide={handleClose}>
        <Modal.Header >

          <Button closeButton className="close-btn" onClick={handleClose}><i className="fa fa-close"></i></Button>
          <Modal.Title>Confirmation</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col md={12}>
              <h3 style={{ display: "flex", justifyContent: "center" }}>
                Are you sure you want to delete this collection as well the NFT present inside the collection?
              </h3>
            </Col>
          </Row>
        </Modal.Body>

        <Modal.Footer style={{ justifyContent: "center" }}>
          <Row>
            <Col md={6}>
              <Button
                onClick={() => deleteCollection(colData.id)}
                style={{ minWidth: "195px" }}
                className="reg-btn w-100 big"
              >
                Yes
              </Button>
            </Col>
            <Col md={6}>
              <Button
                onClick={handleClose}
                style={{ minWidth: "195px" }}
                className="reg-btn w-100 trans big"
              >
                Cancel
              </Button>
            </Col>
          </Row>
        </Modal.Footer>
      </Modal>
      <div className="collection-post" style={{ position: "relative" }}>
        <div className="banner-pnl" >
          <div
            // style={{ backgroundColor: 'green', height: '100%', width: '100%' }}
            className="bg-layer"
            style={{
              backgroundImage: `url(${API_URL}${colData?.bannerImage?.replaceAll(
                "\\",
                "/"
              )})`,
            }}
          >

            {/* <img
              src={`${API_URL}${colData?.bannerImage?.replaceAll("\\", "/")}`} height={"100%"} width={'100%'} style={{ objectFit: 'contain' }}
              alt="Banner Image"
            /> */}
          </div>
          {colData.isApproved ? (

            <i style={{ color: 'green' }} className="fa fa-check-circle custom-circle" ></i>
          ) : (

            <i style={{ color: 'red' }} className="fa fa-times  custom-circle" aria-hidden="true"></i>
          )}
          <Dropdown className="post-menu">
            <Dropdown.Toggle id="dropdown-basic">
              <i className="fa fa-ellipsis-h"></i>
            </Dropdown.Toggle>

            <Dropdown.Menu className="my-dropdown-menu">

              <Dropdown.Item href={`/Collection/${colData.id}`}>
                View
              </Dropdown.Item>
              {rightAccess?.some(
                (right) =>
                  right.screenName.toLowerCase() ===
                  "nft collection management" && right.isUpdate
              ) && (
                  <Dropdown.Item href={`/updateCollection/${colData.id}`}>
                    Edit
                  </Dropdown.Item>
                )}

              {rightAccess?.some(
                (right) =>
                  right.screenName.toLowerCase() ===
                  "nft collection management" && right.isDelete
              ) && (
                  <Dropdown.Item
                    onClick={() => setisDelete(true)}
                    href="#"
                  >
                    Delete
                  </Dropdown.Item>
                )}
              {!colData.isApproved && (
                <Dropdown.Item
                  onClick={() => { approveCollection(colData.id, true) }}
                  href="#"
                >
                  Approve
                </Dropdown.Item>
              )}

              {colData.isApproved && email === 'superadmin@gmail.com' && (
                <Dropdown.Item
                  onClick={() => { approveCollection(colData.id, false) }}
                  href="#"
                >
                  Un approve
                </Dropdown.Item>
              )}


            </Dropdown.Menu>
          </Dropdown>
        </div>
        <Link to={`/Collection/${colData.id}`} className="txt-pnl">
          <div className="img-pnl">
            <div className="img-pnl-inner">
              <img src={`${API_URL}${colData.logoImage}`} alt="User" />
            </div>

          </div>
          <div className="heading">
            <h4>{colData.name}</h4>
            <p>
              created by <span>{colData.ownerName}</span>
            </p>
          </div>
          <div className="flex-div">
            <h5>
              {colData.totalNft}
              <span>Items</span>
            </h5>

            {/* <h5>
              4.1K
              <span>Owners</span>
            </h5> */}
            <h5 >


              {colData.totalVolume}{' '} <img height={20} width={20} src={Logo} alt="logo" />

              <span>Volume</span>
            </h5>
          </div>
        </Link>
      </div>
    </>
  );
}
export default NFTCollectionPost;
